<template>
    <div class="belege-detail-container">
        <div v-if="item" class="content">
            <h2>{{item.title}} :  {{item.belegnummer}}</h2>
            <hr>
            <br>
            <div style="float: left;">
                {{item.anschrift.vorname}} {{item.anschrift.nachname}}<br>
                {{item.anschrift.zusatz}}<br>
                {{item.anschrift.strasse}}<br>
                {{item.anschrift.land}} - {{item.anschrift.plz}} {{item.anschrift.ort}}
            </div>
            <div style="float: right;">
                <span v-for="(field,index) in item.top_right"
                      :key="index"

                >
                    {{index}} : {{field}}<br>
                </span>
            </div>
            <div style="clear:both"></div>
            <br><br>
            <table cellpadding="2" cellspacing="0" border="1" width="100%">
                <tr>
                    <th v-for="(col,name) in item.content_table.rows[0]" :key="name">
                        {{name}}
                    </th>
                </tr>
                <tr v-for="(row,index) in item.content_table.rows" :key="index">
                    <td v-for="(col,i) in row" :key="i">
                        {{col}}
                    </td>
                </tr>
                <tr>
                    <td :colspan="Object.keys(item.content_table.rows[0]).length-2"></td>
                    <td>Nettobetrag:</td>
                    <td>{{item.content_table.netto}}</td>
                </tr>
                <tr>
                    <td :colspan="Object.keys(item.content_table.rows[0]).length-2"></td>
                    <td>Bruttobetrag:</td>
                    <td>{{item.content_table.brutto}}</td>
                </tr>
            </table>
            <br><br>
            <table cellpadding="2" cellspacing="0" border="1" width="100%">
                <tr>
                    <th v-for="(col,name) in item.bottom" :key="name">
                        {{name}}
                    </th>
                </tr>
                <tr>
                    <td v-for="(col,i) in item.bottom" :key="i">
                        {{col}}
                    </td>
                </tr>
            </table>
            <br>
            <div style="text-align: right">
                <button id="print-btn" title="Drucken"><font-awesome-icon icon="print" /> Drucken</button>
            </div>
        </div>
    <font-awesome-icon icon="table" @click="$emit('change-view','table')" />
    </div>
</template>

<script>
  //import axios from 'axios'
  export default {
    name: 'BelegeView',
    props:{
      item:Object
    },
    data:function(){
      return{
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .belege-detail-container{
        text-align: center;
    }
    .belege-detail-container > .svg-inline--fa{
        float:right;margin-top:0.8em;margin-right:0.8em;
    }
    .belege-detail-container .content{
        display:inline-block;
        background-color: #fff;
        border:1px solid #ececec;
        border-radius:0.25em;
        padding:1em;
        text-align: left;
        width:75%;
        margin-top: 1em;
    }
    .belege-detail-container .content th{
        white-space: nowrap;
    }
    .belege-detail-container #print-btn{
        background-color:#c6d72f;
        padding:0.5em 1em;
        border: 1px solid #ececec;
        border-radius:3px;
        cursor: pointer;
        color:#fff;
        font-size:100%;
    }
    .belege-detail-container #print-btn .svg-inline--fa{
        color:#fff;
    }
</style>