<template>
    <div class="tree-container">
        <div class="tree-grid">
            <ul class="item" v-if="treeData && treeData.nodes">
                <li>
                    <span>
                        <font-awesome-icon class="expandToggle" title="Erweitern" v-if="!expandAllToggle" @click="expandToggle" icon="angle-double-down" />
                        <font-awesome-icon class="expandToggle" title="Zusammenklappen" v-if="expandAllToggle" @click="expandToggle" icon="angle-double-up" />
                    </span>
                    <span v-for="(key,ix) in gridColumns"
                        @click="sortBy(key)"
                        :key="ix"
                        :class="{ headcolumn:true,active: sortKey == key}">
                        {{ key | capitalize }}
                        <!--span class="arrow" :class="sortOrders[key] > 0 ? 'asc' : 'dsc'"-->
                            <font-awesome-icon v-if="sortKey == key && sortOrders[key] > 0" icon="caret-up" />
                            <font-awesome-icon v-if="sortKey == key && sortOrders[key] < 1" icon="caret-down" />
                        <!-- /span -->
                    </span>
                    <span v-show="gridColumns.length>3" style="text-align:center">
                        <font-awesome-icon class="view" title="Detailansicht" icon="file-invoice" @click="$emit('change-view','detail')"/>
                    </span>
                </li>


                <!-- tr v-for="(entry,ix) in filteredRows"  :key="ix">
                    <td v-for="(key,ix) in gridColumns"  :key="ix">
                        {{entry[key]}}
                    </td>
                </tr -->
                <GridItem
                        v-for="(node, index) in filteredRows"
                        :key="index"
                        :item="node"
                        :columns="gridColumns"
                        :filter="searchQuery"
                        @item-detail-open="openItemDetail"
                        :selectedItem="selectedItem"
                        ref="collapsible"
                />
            </ul>
        </div>

    </div>
</template>

<script>
  import GridItem from './GridItem.vue'

  export default {
    name: 'BelegeGrid',
    props: {
      treeData:Object,
      selectedItem:Object,
      restrictedColumns:Array,
      searchQuery:String,
      reset:Boolean
    },
    data() {
      return {
        gridColumns: [],
        sortOrders:[],
        expandAllToggle:false,
        sortKey:null
      }
    },
    components:{
      GridItem
    },
    mounted:function(){
      //console.log('mounted',this.treeData);
      this.sortKey= '';
          this.sortOrders =this.getColumns(this.treeData,{});
          this.gridColumns=[];

          if(this.restrictedColumns.length>0){
            this.gridColumns=this.restrictedColumns;
          }
          else {
            for (var ix in this.sortOrders) {
              if (ix != 'nodes' && ix != 'class' && ix != 'style' && ix != 'disabled' && !ix.startsWith('data-')
              ) {
                this.gridColumns.push(ix)
              }
            }
          }
    },
    updated:function(){
      //console.log('updated ',this.treeData);
    },
    beforeUpdate:function(){
      //console.log('beforeUpdate',this.treeData);
    },
    computed: {
      filteredRows: function () {
        //console.log('filteredRows');
        var sortKey = this.sortKey
        var filterKey = this.searchQuery && this.searchQuery.toLowerCase()
        //clone tree
        var _data =  this.getCloneTree(this.treeData.nodes)
        //console.log('filteredRows',_data);

        if (filterKey||this.restrictedColumns.length>0) {
          _data = this.filterColumns(_data,filterKey)
        }
        //console.log('filteredRows',sortKey)
        if (sortKey) {
          _data= this.sortColumns(_data,sortKey)
        }
        return _data
      }
    },
    watch: {
      searchQuery: function() {
        this.$refs.collapsible.map(c => c.isToggle =  false)
      },
      reset:function(){
        this.sortKey=null;
      }
    },
    filters: {
      capitalize: function (str) {
        return str.charAt(0).toUpperCase() + str.slice(1)
      }
    },
    methods: {
      sortBy: function (key) {
        this.sortKey = key
        this.sortOrders[key] = this.sortOrders[key] * -1
        //console.log('sortBy',key,this.sortOrders[key] )
      },
      expandToggle:function(){
        this.expandAllToggle=!this.expandAllToggle
        this.$refs.collapsible.map(c => c.isToggle =  true)
        this.$refs.collapsible.map(c => c.isOpen = this.expandAllToggle)
      },
      getColumns(data,sortOrders){
        for(var item in data) {
            if(isNaN(item)) {
              sortOrders[item] = 1
              if(item == 'nodes'){
                this.getColumns(data[item],sortOrders)
              }
            }
            else{
              this.getColumns(data[item],sortOrders)
            }
        }
        return sortOrders;
      },
      sortColumns(data,sortKey){
        var order = this.sortOrders[sortKey] || 1
        //console.log(sortKey)
        var _data = data.slice().sort(function (a, b) {
          if((typeof a.class!="undefined" && a.class.match(/.*summary.*/i))
            || (typeof b.class!="undefined" && b.class.match(/.*summary.*/i))
          ){
            return 1;
          }
          var parts = String(a[sortKey]).match(/(\d+)/g);
          var d = null;
          if(typeof parts !="undefined" && parts && parts.length>2){
            d = Date.parse(parts[2]+"-"+parts[1]+"-"+parts[0]);
          }
          if(d &&!isNaN(d)){
            a = new Date(d)
          }
          else if(isNaN(a[sortKey])) {
            a = String(a[sortKey]).replace(' ', '_').toLowerCase()
          }
          else if(a[sortKey]!=""){
            a = a[sortKey]
          }
          parts = String(b[sortKey]).match(/(\d+)/g);
          var d2 = null;
          if(typeof parts !="undefined" && parts && parts.length>2){
            d2 = Date.parse(parts[2]+"-"+parts[1]+"-"+parts[0]);
          }
          if(d2 && !isNaN(d2)){
            b = new Date(d2)
          }
          else if(isNaN(b[sortKey])) {
            b = String(b[sortKey]).replace(' ', '_').toLowerCase()
          }
          else if(b[sortKey]!=""){
            b = b[sortKey]
          }
          //console.log(sortKey,a,b, a > b ? 1 : -1)
          if(d && !isNaN(d) && d2 && !isNaN(d2)){
            return  (a.getTime() === b.getTime() ? 0 : a.getTime() > b.getTime() ? 1 : -1) * order
          }
          else{
            return  (a === b ? 0 : a > b ? 1 : -1) * order
          }

        })

        for(var i in data) {
          for(var item in data[i]) {
            if(isNaN(item)) {
              if(item == 'nodes'){
                data[i][item] = this.sortColumns(data[i][item],sortKey)
              }
            }
          }
        }
        return _data
      },
      getCloneTree(data){
        var str =JSON.stringify(data);
        return JSON.parse(str);
      },
      filterColumns(data,query) {
        //console.log('filterColumns 1',data)
        var regEx = new RegExp(".*"+query+".*","i"),_this=this;
        data = data.filter(function (row) {
          //console.log(_this.restrictedColumns,row.class)
          if( _this.restrictedColumns.length>0 && typeof row.class!="undefined" && row.class.match(/.*summary.*/i) ){
            return false
          }
          return Object.keys(row).some(function (key) {
            if(key !='class' && key !='style' && key !='disabled' && !key.startsWith('data-')) {
              //return String(row[key]).toLowerCase().indexOf(query) > -1
              return regEx.test(JSON.stringify(row[key]));
            }
            else{
              return false
            }
          })
        })
        for(var i in data) {
        for(var item in data[i]) {
          //console.log('filterColumns 2',item,)
          if(isNaN(item)) {
            if(item == 'nodes'){
              //console.log('filterColumns 3',item,data[i][item])
              data[i][item] = this.filterColumns(data[i][item],query)
            }
          }
        }
        }
        return data
      },
      openItemDetail:function(item){
        //console.log('Grid','openItemDetail',item)
        this.$emit('item-detail-open',item)
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >
.svg-inline--fa{
    font-size:120%;
    color:#454c99;
}

.expandToggle,.view{
    /*margin-left:0.4em;*/
    cursor: pointer;
}
.tree-grid{
    background-color: #F2F2F2;
}
.headcolumn {
    cursor:pointer;
    color:#454c99;
    font-weight:bold;
    font-size:110%;
    background-color: #F2F2F2;
    white-space: nowrap;
    padding-right: 1em;
    border-radius:0px;
    margin:0px
}
.headcolumn .fa-caret-down,.headcolumn .fa-caret-up{
    position:absolute;
    margin-left:0.2em;

}
.belege-app-container ul{width:100%;border:0px solid #000; padding:0;border-top: 1px solid #ececec;margin:0px}
.belege-app-container ul ul{border:none;}
.belege-app-container li{ list-style: none;white-space: normal; display: flex; flex-wrap:nowrap;flex-direction:row;}

.belege-app-container li span{background-color: #fff;flex:1;display: inline-block;border-top:1px solid #ececec;border-left:1px solid #ececec;border-bottom:1px solid #ececec;border-right:1px solid #ececec;padding:0.5em;margin:0px;  border-radius:0.25em;margin:0px;}
.belege-app-container li span span{padding:0;border:none;}
.belege-app-container li > span:nth-child(1){
    border-left: 1px solid #ececec;
}
.belege-app-container li span:nth-child(2) span{border-left:none;background-color:transparent;}
.belege-app-container li > span:nth-child(1),li > span:nth-last-child(1){flex-basis:1em;max-width: 1em;min-width: 1em;background-color: #F2F2F2;margin:0px;  border-radius:0px;border-color:transparent}
.belege-app-container li > span:nth-last-child(1){max-width: 2.7em;padding: 0.5em 0.1em;/*border-left: 0px solid #ececec;*/}
.belege-app-container li span:nth-child(2){flex-grow:1;}

/*optische Trennung der Äste*/
.belege-app-container ul ul li {margin-top:0.8em;border-top: 0px solid #ececec;}
.belege-app-container ul ul ul li,
.belege-app-container ul ul:nth-child(2) li {margin-top:0em;border-top: none;}
/**/

.belege-app-container ul ul ul li span:nth-child(2) .text{padding-left:1em;}
.belege-app-container ul ul ul ul li span:nth-child(2) .text{padding-left:2em;}
.belege-app-container ul ul ul ul ul li span:nth-child(2) .text{padding-left:3em;}
.belege-app-container ul ul ul ul ul ul li span:nth-child(2) .text{padding-left:4em;}
.belege-app-container ul ul ul ul ul ul ul li span:nth-child(2) .text{padding-left:5em;}
.belege-app-container ul ul ul ul ul ul ul ul li span:nth-child(2) .text{padding-left:6em;}

/**/
</style>
