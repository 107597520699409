import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFolder,faFolderOpen,faPlusSquare,faMinusSquare,faSearch,faFilter,
  faMoneyCheck,faAngleDoubleDown,faAngleDoubleUp,faCaretDown,faCaretUp,
  faSearchPlus,faUndo,faBinoculars,faCalendarDay,faPrint,faFileInvoice,faTable,
  faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faFolder,faFolderOpen,faPlusSquare,faMinusSquare,faSearch,faFilter,
    faAngleDoubleDown,faAngleDoubleUp,faCaretDown,faCaretUp,faMoneyCheck,
    faSearchPlus,faUndo,faBinoculars,faCalendarDay,faPrint,faFileInvoice,faTable,
    faExclamationTriangle)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#belege-app')
