<template>
    <div class="belege-app-container">
        <div v-if="loading" class="loading">
            <img src="../assets/loading.svg">
        </div>

        <div v-else-if="error" class="error">
            <font-awesome-icon icon="exclamation-triangle" /> {{ error }}
        </div>

        <div v-else>

        <form id="search" class="search">
            <fieldset>
                <legend><font-awesome-icon icon="folder-open" />Suche</legend>
                <table border="0" cellspacing="3" cellpadding="0" width="100%">
                    <colgroup>
                        <col style="width:22%;"/>
                        <col style="width:22%;"/>
                        <col style="width:22%;"/>
                        <col style="width:24%;"/>
                        <col style="width:5%;"/>
                        <col style="width:5%;"/>
                    </colgroup>
                    <tr>
                        <td>
                            <div class="filter-input">
                                <font-awesome-icon icon="calendar-day" />
                                <datepicker placeholder="Von ..." v-model="searchDateFrom"></datepicker>
                            </div>
                        </td>
                        <td>
                            <div class="filter-input">
                                <font-awesome-icon icon="calendar-day" />
                                <datepicker placeholder="Bis ..." v-model="searchDateTo"></datepicker>
                            </div>
                        </td>
                        <td><input id="" name="offeneAuftraege" type="checkbox" v-model="searchOffeneAuftraege" > Nur offene Aufträge </td>
                        <td rowspan="2">
                            <fieldset id ="extended-search-fieldset" >
                                <legend>Erweiterte Suche</legend>
                                <table border="0" cellspacing="0" cellpadding="0" width="100%">
                                    <tr>
                                        <td>Felder</td>
                                        <td>Belege Typ</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <select v-model="searchExtendedField">
                                                <option value="noChoice">Bitte auswählen ...</option>
                                                <option value="artikelnummer">Artikelnummer</option>
                                                <option value="seriennummer">Seriennummer</option>
                                                <option value="name">Empfänger</option>
                                                <option value="artBez">Artikelbezeichnung</option>
                                            </select>
                                        </td>
                                        <td>
                                            <select v-model="searchExtendedTyp">
                                                <option value="noChoice">Bitte auswählen ...</option>
                                                <option>Aufträge</option>
                                                <option>Lieferscheine</option>
                                                <option>Rechnungen</option>
                                                <option>Gutschriften</option>
                                                <option>IMEI-Picking</option>
                                                <option>Rückstände</option>
                                                <option>Bildung</option>
                                            </select>
                                        </td>
                                    </tr>
                                </table>
                            </fieldset>

                        </td>
                        <td rowspan="2" ><button id="search-btn" title="Filter Anwenden"><font-awesome-icon icon="search" /></button></td>
                        <td rowspan="2" ><button title="Zurücksetzen" v-on:click="resetForm"><font-awesome-icon icon="undo"/></button></td>
                    </tr>
                    <tr>
                        <td colspan="3">
                            <div class="filter-input">
                                <font-awesome-icon icon="filter" />
                                <input id="query" placeholder="Suche..." name="query" v-model="searchQuery">
                            </div>
                        </td>

                    </tr>
                </table>

            </fieldset>

        </form>

            <BelegeGrid v-if="viewMode=='table'"
                        :treeData="treeData"
                        :restrictedColumns="[]"
                        :searchQuery="searchQuery"
                        :reset="reset"
                        @change-view="changeView"
                        @show-loading="showLoading"
                        @show-error="showError"
                        @item-detail-open="openItemDetail"
            ></BelegeGrid>
            <div v-else class="detail-view">
                <div class="col1">
                <BelegeGrid
                            :treeData="treeData"
                            :restrictedColumns="restrictedColumns"
                            :reset="reset"
                            :searchQuery="searchQuery"
                            @item-detail-open="openItemDetail"
                            @show-loading="showLoading"
                            @show-error="showError"
                            :selectedItem="selectedItem"
                ></BelegeGrid>
                </div>
                <div class="col2">
                    <BelegeDetail
                            @change-view="changeView"
                            @show-loading="showLoading"
                            @show-error="showError"
                            :item="detailItem"
                    ></BelegeDetail>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import BelegeGrid from './BelegeGrid.vue'
  import BelegeDetail from './BelegeDetail.vue'
  import Datepicker from 'vuejs-datepicker';
  import axios from 'axios'

  export default {
    name: 'BelegeView',
    components: {
      BelegeGrid,
      Datepicker,
      BelegeDetail
    },
    data:function () {
      return {
        loading: null,
        error: null,
        treeData: {
          name: '',
          nodes: []
        },
        restrictedColumns:['Belegnummer'],
        reset:false,
        searchQuery: '',
        searchDateFrom: null,
        searchDateTo: null,
        searchOffeneAuftraege: false,
        searchExtendedField: 'noChoice',
        searchExtendedTyp: 'noChoice',
        viewMode:'table',
        customernumber:null,
        detailItem:null,
        selectedItem:null,
        detailsCache:[]
      }
    },
    methods: {
      resetForm:function(){
        //console.log('resetForm',this)
        this.reset= !this.reset;
        this.searchQuery= '';
        this.searchDateFrom= null;
        this.searchDateTo=  null;
        this.searchOffeneAuftraege = false;
        this.searchExtendedField= 'noChoice';
        this.searchExtendedTyp= 'noChoice';
      },
      fetchData () {
        this.error = null
        this.loading = true
        // eslint-disable-next-line
        var _id=typeof _customernumber_!="undefined" ? _customernumber_ :this.$route.params.id;

        axios.get('dummy.json/?id='+_id+'/')
        .then(response=>{
          // handle success
          this.treeData = response.data
          if(this.treeData.nodes.length<1){
            this.error="Keine Daten vorhanden"
          }
        }).catch(error=> this.error = error.toString())
        .finally(f=>this.loading = f);

      },
      fetchDetailData (beleg) {
        if(beleg){
          //console.log('BelegView','fetchDetailData',beleg,beleg['data-detail-typ'],beleg['data-detail-id'])
          this.loading = true
          axios.get('dummy_detail.json/?typ='+beleg['data-detail-typ']+'&id='+beleg['data-detail-id']+'/')
          .then(response=> this.detailsCache[beleg['data-detail-id']] = this.detailItem =response.data)
          .catch(error=>this.error=error.toString())
          .finally(f=>this.loading = f);
        }
        else{
          this.error='Error: kein Beleg übergeben'
        }
      },
      changeView:function(mode){
        this.viewMode=mode
      },
      showLoading:function(value){
        this.loading=value;
      },
      showError:function(value){
        this.error=value;
      },
      openItemDetail:function(item){
        //console.log('View','openItemDetail',item)
        if(!isNaN(item['data-detail-id']) && isNaN(item['data-detail-typ'])) {
          this.viewMode = 'detail'
          this.selectedItem=item
          var found =false;
          for (var key in this.detailsCache) {
            if (key === 'length' || !this.detailsCache.hasOwnProperty(key)) continue;
            if(key == item['data-detail-id']){
              found=true
            }
          }
          if (!found) {
            this.fetchDetailData(item)
          }
          else{
            this.detailItem =this.detailsCache[item['data-detail-id']]
          }
        }
        else{
          this.error='Error: kein Beleg übergeben'
        }
      }
    },

    created () {
      //console.log(this.$route.params);
      this.fetchData()
    },
    watch: {
      '$route': 'fetchData'
    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .belege-app-container{
        margin:0;
        padding:0;
        border: 1px solid #ececec;
        background-color: #fafafa;
        border-radius:4px;
        min-width:61em;
        width: 100%;
        height:100%;
    }
    .belege-app-container form.search{
        margin:0 0.5em;
        padding:1em 0;
    }
    .belege-app-container form.search select{
        padding:0.3em 1em;
        border: 1px solid #ececec;
        border-radius:3px;
    }
    .belege-app-container form.search button{
        padding:0.8em 1.2em 0.8em 1.2em;
        background-color: #454c99;
        border: 1px solid #ececec;
        border-radius:3px;
        cursor: pointer;
    }
    .belege-app-container form.search button#search-btn{
        background-color: #c6d72f;
    }
    .belege-app-container form.search button .svg-inline--fa{
        color:#fff;
    }
    .belege-app-container .detail-view{
        overflow:auto;
        width:calc(100% - 2px);
        border: 1px solid #ececec;
        background-color: #fafafa;
        border-radius:0px 0px 4px 4px;
    }
    .belege-app-container .detail-view .col1{
        float: left;
    }
    .belege-app-container .detail-view .col2{
        width:auto;
    }
    .filter-input{
        border: 1px solid #ececec;
        border-radius:3px;
        background-color:#fff;
    }
    .filter-input .vdp-datepicker{
        position: static;
        background-color:transparent;
    }
    .filter-input .vdp-datepicker__calendar{
        margin-left: 2.3em;
    }
    .filter-input .svg-inline--fa{
        /*position: relative;
        top:0.4em;
        left:1.5em;*/
        float:left;
        width:1em;
        padding: 0.35em 0.5em 0.35em 0.5em;
    }
    .filter-input input{
        padding: 0.7em 0.5em 0.5em 0.5em;
        width: calc(100% - 4em);
        border:none;
        background-color:transparent;
    }

    .belege-app-container form.search fieldset{
        border: 1px solid #ececec;
    }
    .belege-app-container form.search fieldset td{
        text-align: center;
    }

    .belege-app-container form.search fieldset fieldset{
        font-size: 90%;
        margin:0 1em;

    }
    .belege-app-container form.search fieldset legend{
        padding:0 0.5em 0 0.3em;
        margin-left:-0.3em;
        font-weight: bold;
        color:#454c99;
    }
    .belege-app-container form.search fieldset legend .svg-inline--fa{
        font-size:100%;
        margin-right:0.5em;
    }
    .loading{
        position: fixed;
        z-index: 99999;
        width: 100%;
        height: 100%;
    }
    .loading img{
        position: fixed;
        opacity: .5;
        top: 50%;
        left: 50%;
        margin-left: -100px;
        margin-top: -100px;
        height: 200px;
        -webkit-animation-name: rotate;
        animation-name: rotate;
        -webkit-animation-duration: .8s;
        animation-duration: .8s;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
    }
    @keyframes rotate {
        from {
            transform: rotate(0deg);
        } to {
              transform: rotate(360deg);
          }
    }
    .error{
        border:1px solid indianred;
        color:indianred;
        padding:1.5em;
        font-weight:bold;
        font-size:130%;
        text-align: center;
    }
</style>
<style>.vdp-datepicker input{

    padding: 0.7em 0.5em 0.5em 0.5em;
    width: calc(100% - 4em);
    border:none;
    background-color:transparent;
}</style>