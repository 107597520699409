<template>
  <div id="kundencenter-belege-app">
    <!--img alt="Vue logo" src="./assets/logo.png" -->
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'kundencenter-belege-app',
}
</script>

<style>
  /*Resets für herweck.de theme*/
  #kundencenter-belege-app,
  #kundencenter-belege-app table,
  #kundencenter-belege-app span,
  #kundencenter-belege-app ul,
  #kundencenter-belege-app li,
  #kundencenter-belege-app form,
  #kundencenter-belege-app div,
  #kundencenter-belege-app legend,
  #kundencenter-belege-app fieldset,
  #kundencenter-belege-app a,
  #kundencenter-belege-app svg,
  #kundencenter-belege-app *{
    border-collapse:separate;
    border-spacing:2px;
    box-sizing:content-box;

  }
  #kundencenter-belege-app .vdp-datepicker__calendar .cell{
    width:10%;
  }
  .belege-app-container .svg-inline--fa {
    font-size: 120%;
    color: #454c99;
  }
  .belege-app-container .detail-action.svg-inline--fa {
    cursor:pointer;
    color:#c6d72f;
    margin-left:0.2em;
  }
  .belege-app-container fieldset{
    min-width:auto;
    padding:initial;
    margin:initial;
    padding-top: 0.35em;
    padding-bottom: 0.625em;
    padding-left: 0.75em;
    padding-right: 0.75em;
  }

  .belege-app-container fieldset legend{
    font-size:inherit;
    margin-bottom:initial;
    border-bottom:none;
    width:auto;
  }

  .belege-app-container input,
  .belege-app-container button,
  .belege-app-container select,
  .belege-app-container textarea,
  .belege-app-container button{
    line-height:initial
  }

</style>
