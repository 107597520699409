<template>
    <ul :class="getItemClasses">
        <li>
        <span :class="{folder: isFolder}">
            <span v-if="isFolder"
                       :class="{toggle}"
                       @click="toggle" >
                <font-awesome-icon v-show="checkOpen" icon="minus-square" />
                <font-awesome-icon v-show="!checkOpen" icon="plus-square" />
                    <!-- [{{ checkOpen? '-' : '+' }}] -->
            </span>
        </span>
        <span
            v-for="(key,index) in columns"
            :key="index"
            :class="getColumnClasses"
            :disabled="{disabled: isDisabled}">
            <span v-if="index==0 && hasDetail" class="text" :style="item.style">
                <a  @click="$emit('item-detail-open',item)">
                    {{ item[key] | beautify}}
                </a>
            </span>
            <span v-else class="text" :style="item.style">
                   {{ item[key] | beautify}}
            </span>
        </span>
        <span v-show="columns.length>1">
                <font-awesome-icon
                        v-if="hasDetail"
                        title="Detailansicht öffnen"
                        class="detail-action"
                        @click="$emit('item-detail-open',item)"
                        :data-detail-id="item['data-detail-id']"
                        icon="file-invoice" />
                <font-awesome-icon
                    v-if="hasDetail"
                    title="Beleg drucken"
                    class="detail-action"
                    @click="openPrint"
                    :data-detail-id="item['data-detail-id']"
                    icon="print" />
        </span>
        </li>

            <GridItem
                    v-show="checkOpen"
                    class="item"
                    v-for="(child, index) in item.nodes"
                    :key="index"
                    :item="child"
                    :columns="columns"
                    :filter="filter"
                    @item-detail-open="openItemDetail"
                    @toggle-parent="toggleOnActivate"
                    :selectedItem="selectedItem"
                    ref="childs"
            ></GridItem>
            <!--li class="add" @click="$emit('add-item', item)">+</li -->
    </ul>
</template>

<script>
export default {
    name: 'GridItem',
    props: {
      item: Object,
      selectedItem: Object,
      columns:{
        type:Array
      },
      filter:String
    },
    data: function () {
      return {
        isOpen: false,
        isToggle: false,
      }
    },
  /*watch: {
      expandAll: function(value) {
        //console.log('watch',this.expandAll,value)
        if(value===false||value===true) {
          this.isToggle = false
          this.isOpen = value
          this.expandAll = this.isOpen
        }
      }
    },*/
    /*mounted:function(){
        console.log('item mounted',this.isOpen,this.isToggle);
        if(
            (!this.isToggle && String(this.filter).trim().length > 0)
        ){
          this.isOpen = true
        }
    },*/
    beforeUpdate:function(){
        //console.log('item beforeUpdate',this.isOpen,this.isToggle);
        if(
            (!this.isToggle && String(this.filter).trim().length > 0)

        ){
          this.isOpen = true
        }
        if(typeof this.$refs.childs !="undefined") {
          this.$refs.childs.map(c => c.isToggle = true)
          this.$refs.childs.map(c => c.isOpen = this.isOpen)
        }

    },
    computed: {
      isFolder: function () {
        return this.item.nodes &&
            this.item.nodes.length
      },
      isDisabled: function () {
        return this.item.disabled ?true:false
      },
      hasDetail:function(){
        return !isNaN(this.item['data-detail-id'])
      },
      isActiveItem:function(){
        /*if(this.selectedItem) {
          console.log(this.columns.length,!this.columns.length > 3 ==false
              , !isNaN(this.item['data-detail-id'])
              , this.selectedItem
              , !isNaN(this.selectedItem['data-detail-id'])
              , this.item['data-detail-id'], this.selectedItem['data-detail-id'],this.item['data-detail-id'] == this.selectedItem['id'])
        }//*/
        if(
            this.selectedItem
            && this.columns.length>1==false
            && !isNaN(this.item['data-detail-id'])
            && !isNaN(this.selectedItem['data-detail-id'])
            && this.item['data-detail-id'] == this.selectedItem['data-detail-id']
        ){
          //console.log('true',this.item['data-detail-id'] , this.selectedItem['data-detail-id'])
          this.$emit('toggle-parent')
          return true
        }
        else{
          //console.log('false',this.item['data-detail-id'] )
          return false
        }

      },
      checkOpen:function(){
        //console.log('checkOpen',this.isOpen,this.isToggle,this.filter,String(this.filter).trim().length);
        return this.isOpen || (!this.isToggle && String(this.filter).trim().length > 0 )
      },
      getColumnClasses:function(){
        var _class = this.isFolder ?'folder ':''
        _class += this.item.class?this.item.class+' ':''
        if(this.isActiveItem){
          _class += 'active'
        }
        return _class
      },
      getItemClasses:function(){
          var _class = this.item.class?this.item.class:''
          _class = this.columns.length>1 ? '' : ' restricted-columns'
        return _class
      }
    },
    filters: {
        beautify: function (str) {
          if(!isNaN(str) && String(str)!=""){
            str=new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(str)
          }
          else{
            str=str.replace(/([^a-zäüöß.?!0-9])/i," $1 ")
          }
          return str
        }
    },
    methods: {
      toggleOnActivate: function () {
        if (this.isFolder && !this.isOpen) {
          //console.log('toggleOnActivate',this.isOpen,this.isToggle);
          this.isToggle=true
          this.isOpen = true
          //this.$emit('filter-update',this.filter==null?'':this.filter)
          this.$emit('toggle-parent')
        }
      },
      toggle: function () {
        if (this.isFolder) {
          //console.log('toggle',this.isOpen,this.isToggle);
          this.isToggle=true
          this.isOpen = !this.isOpen
          //this.$emit('filter-update',this.filter==null?'':this.filter)
        }
      },
      openItemDetail:function(item){
        //console.log('GridItem','openItemDetail',item)
        this.$emit('item-detail-open',item)
      },
      openPrint:function(){

      }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .item {
        /*cursor: pointer;*/
    }
    .item .text a{
        cursor:pointer
    }
    .item .active{
        background-color:#c6d72f;
        color:#fff;
        font-weight:bold;
    }
    .folder{

    }
    .toggle{
        cursor:pointer;
        /*margin-left:0.45em;*/
    }
    .detail-action {
        cursor:pointer;
        color:#c6d72f;
        margin-left:0.2em;
    }
    .restricted-columns li span:nth-child(2){
        /*border-right:none;*/
    }

</style>